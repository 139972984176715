<template>
  <section @click.stop.prevent="setActiveCell()">
    <div v-if="hasData">
      <div class="toolbar pull-right text-right" v-if="hasData">
        <span
          class="btn btn-primary btn-sm"
          v-if="filteredConnectorList.length"
          @click.stop.prevent="showConnectorSelector = !showConnectorSelector"
        >
          <i v-if="showConnectorSelector" class="fa fa-refresh fa-spin"></i>
          {{ $t("destination_connector") }}
          <i
            :class="
              showConnectorSelector ? 'fa fa-chevron-up' : 'fa fa-chevron-down'
            "
          ></i>
        </span>
        <div v-if="showConnectorSelector" style="min-width:400px;">
          <v-select
            @search:focus="$emit('focus')"
            @input="addConnector"
            :options="filteredConnectorList"
            :reduce="(item) => item.id"
            label="name"
            class="vselector"
            style=""
            ref="connectorSelector"
          />
        </div>
      </div>
      <div style="font-size: 16pt;font-weight:600;margin-bottom:20px;">
        <span>
          <i class="fa fa-share-alt"></i>
        </span>
        <span style="padding:10px;">
          {{ $t("data_mapping") }}
        </span>
        <Tooltip
          style="font-size:80%"
          :title="$t('hints.connector_data_mapping')"
        />
      </div>
    </div>
    <div class="table-container">
      <template v-if="hasData">
        <div class="overlay" v-if="this.busy">
          <i class="fa fa-refresh fa-spin"></i>
        </div>
        <div
          class="table-content"
          :style="{ 'overflow-x': selectingData ? 'clip' : 'auto' }"
        >
          <table class="table table-bordered table-hover" @click.stop.prevent>
            <thead @click.stop.prevent="setActiveCell()">
              <tr style="background-color: #dcdfe3;">
                <th style="white-space: nowrap" class="text-center">
                  <i class="glyphicon glyphicon-stats"></i>
                  {{ $t("available_for_mapping") }}
                </th>
                <template
                  v-if="
                    destinationConnectorList && destinationConnectorList.length
                  "
                >
                  <th
                    v-for="(connector, ic) in destinationConnectorList"
                    :key="ic"
                    :title="$tc('connector', 1)"
                  >
                    <span class="clicable" :title="$t('titles.select_all')">
                      <i
                        :class="
                          hasMappedData(connector.id)
                            ? 'fa fa-check-square-o'
                            : 'fa fa-square-o'
                        "
                        @click.stop.prevent="toggleDataMapping(connector.id)"
                      ></i>
                    </span>
                    <span style="padding: 0 5px;color:#666;">
                      <i class="fa fa-plug"></i>
                      <span style="font-size:12pt">
                        {{ getConnectorName(connector.id) }}
                      </span>
                    </span>
                    <span
                      class="btn btn-sm btn-trash"
                      :title="$t('remove')"
                      @click.stop.prevent="removeConnector(connector.id)"
                    >
                      <i class="fa fa-trash"></i>
                    </span>
                  </th>
                </template>
                <template v-else>
                  <th class="no-data text-center">
                    <i class="fa fa-plug"></i> {{ $t("connector_list") }}
                  </th>
                </template>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(dataSourceItem, ir) in dataSourceList" :key="ir">
                <td @click.stop.prevent="setActiveCell()">
                  <span style="font-size:12pt;font-weight:600;color:#337ab7;">
                    {{ dataSourceItem.name }}
                  </span>
                </td>
                <template
                  v-if="
                    destinationConnectorList && destinationConnectorList.length
                  "
                >
                  <td
                    v-for="(connector, ic) in destinationConnectorList"
                    :key="ic"
                  >
                    <template v-if="isEditing(dataSourceItem.id, connector.id)">
                      <v-select
                        @search:focus="$emit('focus')"
                        @input="setTargetData(dataSourceItem.id, $event)"
                        @open="selectingData = true"
                        @close="selectingData = false"
                        :options="dataTargetList"
                        :value="targetId(dataSourceItem.id, connector.id)"
                        :reduce="(item) => item.id"
                        :components="{ Deselect }"
                        label="name"
                        class="vselector"
                      >
                        <template
                          v-if="isMultiDevice(connector.id)"
                          #option="{name, deviceName}"
                        >
                          <div v-if="deviceName" class="group-name">
                            {{ deviceName }}
                          </div>
                          <div>{{ name }}</div>
                        </template>
                        <template v-else #option="{name}">
                          <div style="">{{ name }}</div>
                        </template>
                        <template #no-options>
                          {{ $t("there_are_no_data_to_show") }}
                        </template>
                      </v-select>
                    </template>
                    <template
                      v-else-if="getMappedData(dataSourceItem.id, connector.id)"
                    >
                      <span
                        @click.stop.prevent="
                          setActiveCell(dataSourceItem.id, connector.id)
                        "
                        class="clicable"
                        :title="
                          `${targetId(dataSourceItem.id, connector.id)} ${
                            getMappedData(dataSourceItem.id, connector.id).name
                          }`
                        "
                      >
                        <i class="fa fa-check-square-o"></i>
                        <span>
                          {{
                            getMappedData(dataSourceItem.id, connector.id).name
                          }}
                        </span>
                      </span>
                      <span
                        class="btn btn-sm btn-pencil"
                        @click.stop.prevent="
                          setActiveCell(dataSourceItem.id, connector.id)
                        "
                        :title="$t('edit')"
                      >
                        <i class="fa fa-pencil"></i>
                      </span>
                      <span
                        class="btn btn-sm btn-trash"
                        v-if="getMappedData(dataSourceItem.id, connector.id)"
                        @click.stop.prevent="
                          delTargetData(dataSourceItem.id, connector.id)
                        "
                        :title="$t('remove')"
                      >
                        <i class="fa fa-trash"></i>
                      </span>
                    </template>
                    <template v-else>
                      <span
                        class="text-left text-danger clicable"
                        @click.stop.prevent="
                          setActiveCell(dataSourceItem.id, connector.id)
                        "
                        :title="$t('titles.select_data')"
                      >
                        <i class="fa fa-square-o"></i>
                        <span>
                          {{ $t("not_assigned") }}
                        </span>
                      </span>
                      <span
                        class="btn btn-sm btn-pencil"
                        @click.stop.prevent="
                          setActiveCell(dataSourceItem.id, connector.id)
                        "
                        :title="$t('titles.select_data')"
                      >
                        <i class="fa fa-pencil"></i>
                      </span>
                    </template>
                  </td>
                </template>
                <template v-else>
                  <td
                    class="text-center no-data"
                    v-if="ir == 0"
                    :rowspan="dataSourceList.length"
                    style="font-size: 14pt;background-color:whitesmoke;"
                  >
                    {{ $t("no_mapped_destination_connector") }}<br /><br />
                    <div
                      class="btn btn-primary"
                      @click.stop.prevent="showConnectorSelector = true"
                      :disabled="showConnectorSelector"
                    >
                      <span> {{ $tc("select_a_connector", 1) }} </span>
                    </div>
                  </td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
      <template v-else>
        <div class="overlay" v-if="this.busy">
          <i class="fa fa-refresh fa-spin"></i>
        </div>
        <div
          v-else
          class="alert bg-gray alert-dismissible"
          style="margin-top: 20px;"
        >
          <h4>
            <i class="icon fa fa-exclamation-triangle"></i>
            {{ $t("no_data_found") }}
          </h4>
          <div>
            {{ $t("não existem dados disponíveis para mapeamento") }}
          </div>
        </div>
      </template>
      <div class="form-footer">
        <div class="row" v-if="hasData">
          <div class="col-xs-4 text-left">
            <span
              class="btn btn-sm btn-default"
              v-if="hasOriginalMapping"
              @click.stop.prevent="remove"
              :disabled="busy"
            >
              <i class="fa fa-trash"></i>
              {{ $t("remove_mapping") }}
            </span>
          </div>
          <div class="col-xs-8 text-right">
            <span
              class="btn btn-sm btn-default"
              @click.stop.prevent="restore"
              v-if="hasOriginalMapping"
              :disabled="busy"
              style="margin-right:5px"
            >
              <i class="fa fa-undo"></i>
              {{ $t("restore") }}
            </span>
            <span
              class="btn btn-sm btn-default"
              v-if="isValid"
              :disabled="busy"
              @click.stop.prevent="save"
            >
              <i class="fa fa-check"></i>
              {{ $t("save_mapping") }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import MixinAlert from "@/project/mixin-alert.js";
import DataMirroring from "@/services/data-mirroring.js";
import Tooltip from "@/components/tooltip.vue";
export default {
  name: "DataMirroringTableMap",
  mixins: [MixinAlert],
  components: {
    Tooltip
  },
  props: {
    connectorId: {
      type: [Number, String],
      required: false,
      default: ""
    }
  },
  data: () => ({
    Deselect: {
      render: (createElement) =>
        createElement("i", {
          class: "fa fa-close",
          style: { color: "#999" }
        })
    },
    editing: null, // {sourceDataId: null, connectorId: null},
    showConnectorSelector: false,
    destinationConnectorList: null,
    busy: false,
    original: undefined,
    checking: [],
    selectingData: false
  }),
  computed: {
    memoryTypes() {
      // this.$root.config.references.data_memory_types.map((i)=>(i.name))
      /*
       ['Coil Status', 'Holding Register', 'Holding Register (sinalizado)', 'Float - 2 Holding Registers´s', 'Input Register', 'Input Status', 'Float - 2Holding Registers´s - inv(DCBA)', 'Float - 2Holdings Registers´s -inv(CDAB)', 'Long - 2 Holding Registers´s', 'Long - 2 Holding Registers´s -inv(CDAB)', 'Long - 2 Holding Registers´s - inv(DCBA)', 'Float - 2 Input Registers´s', 'Float - 2 Input Registers´s - inv (CDAB)', 'Float - 2 Input Registers´s - inv (DCBA)', 'Long - 2 Input Registers´s', 'Long - 2 Input Registers´s -inv(CDAB)', 'Long - 2 Input Registers´s - inv(DCBA)', 'Bool 8 bits SCPHI', 'Integer 16 bits SCPHI', 'Float 32 bits SCPHI', 'Long 32 bits SCPHI', 'Float - 2 M´s', 'Float - 2M´s - inv (CDAB)', 'Float - 2M´s - inv (DCBA)', 'Unsigned Long 32 bits(2 M´s)', 'Unsigned Long 32 bits(2 Input Registers)', 'ULong 64 bits(4 Holding Registers)', 'Número', 'Amps - TrendPoint (long)', 'Amps - TrendPoint (short)', 'Boolean', 'kWh - TrendPoint']
       */
      return this.$root.config.references.data_memory_types || [];
    },
    connectorList() {
      return this.$store.getters["dashboard/connectorList"] || [];
    },
    filteredConnectorList() {
      return this.connectorList
        .filter(
          (connector) =>
            !(this.destinationConnectorList || []).some(
              (i) => i.id == connector.id
            ) &&
            !connector.base_model &&
            this.connectorId != connector.id &&
            !this.isMQTTConnector(connector)
        )
        .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    },
    dataSourceList() {
      // filtered data that can be mirrored
      return this.getConnectorDataList(this.connectorId)
        .filter((data) => data.allowed_mapping_value)
        .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    },
    dataTargetList() {
      let lst = this.buildTargetList(
        this?.editing?.sourceDataId,
        this?.editing?.connectorId
      );
      if (this.isMultiDevice(this?.editing?.connectorId)) {
        let prev = "";
        lst = lst
          .sort((a, b) => {
            return `${a.device.name}.${a.name}` > `${b.device.name}.${a.name}`
              ? 1
              : `${a.device.name}.${a.name}` < `${b.device.name}.${a.name}`
              ? -1
              : 0;
          })
          .map((i) => {
            if (i.device.name !== prev) {
              prev = i.device.name;
              i.deviceName = i.device.name;
            } else {
              i.deviceName = "";
            }
            return i;
          });
      }
      return lst;
    },
    hasOriginalMapping() {
      return Object.keys(this?.original?.mapping || {}).length ? true : false;
    },
    hasData() {
      return this?.dataSourceList?.length > 0;
    },
    dataList() {
      return this.$store.getters["dashboard/dataList"] || [];
    },
    dataListLength() {
      return (this?.dataList || []).length;
    },
    payload() {
      let payload = null;
      let entry = this.srv.destinationConnectorList2Entry(
        this.destinationConnectorList
      );
      if (entry) {
        payload = {
          id: this.connectorId,
          mapping: entry
        };
        if (this?.original) {
          payload.etag = this.original.etag || "";
        }
      }
      return payload;
    },
    isValid() {
      let mapping = this?.payload?.mapping || null;
      if (!mapping) return false;
      for (var srcDataId in mapping) {
        for (var connId in mapping[srcDataId]) {
          if ((mapping[srcDataId][connId] || []).length) {
            return true;
          }
        }
      }
      return false;
    }
  },
  watch: {
    showConnectorSelector(n) {
      if (n) {
        this.$nextTick(() => {
          if (this?.$refs?.connectorSelector?.$el) {
            let inps = this.$refs.connectorSelector.$el.getElementsByClassName(
              "vs__search"
            );
            if (inps && inps.length) {
              inps[0].focus();
            }
          }
        });
      }
    },
    dataListLength(n, o) {
      let connectorId = (this.checking || []).splice(0, 1)[0];
      if (n > o && connectorId) {
        this.buildSuggestionList(connectorId);
      }
    }
  },
  methods: {
    getConnector(connectorId) {
      return (
        (this.connectorList || []).find(({ id }) => id == connectorId) || null
      );
    },
    getConnectorDataList(connectorId) {
      // actual connector data list
      return this.dataList.filter((data) => data.clp_id == connectorId);
    },
    getDataById(dataId) {
      return (this.dataList || []).find(({ id }) => dataId == id);
    },
    getMappedData(dataSourceId, connectorId) {
      let connMap = (this.destinationConnectorList || []).find(
        ({ id }) => id == connectorId
      );
      let dataId = connMap
        ? connMap.mappedData[dataSourceId] || undefined
        : undefined;
      return dataId ? this.getDataById(dataId) : null;
    },
    targetId(dataSourceId, connectorId) {
      return this.getMappedData(dataSourceId, connectorId)?.id || undefined;
    },
    buildTargetList(sourceDataId, connectorId) {
      let lst = [];
      if (!sourceDataId || !connectorId) {
        return lst;
      }
      let connMap = (this.destinationConnectorList || []).find(
        ({ id }) => id == connectorId
      );
      if (connMap) {
        lst = this.getConnectorDataList(connectorId);
        // TODO: add sourceDataId data type compatibility validation
        // console.log(lst);
        // console.log(connMap?.mappedData);
        var assigned = Object.values(connMap?.mappedData || {}).filter(
          (i) => i != null
        );
        // console.log(assigned);
        if (assigned.length) {
          var currentId = connMap?.mappedData[sourceDataId];
          lst = lst.filter(
            ({ id }) => assigned.indexOf(id) == -1 || currentId == id
          );
        }
        // ordenation
        lst.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      }
      return lst;
    },
    removeConnector(connectorId) {
      let ix = (this.destinationConnectorList || []).findIndex(
        ({ id }) => id == connectorId
      );
      if (ix >= 0) {
        this.destinationConnectorList.splice(ix, 1);
      }
    },
    addConnector(connectorId) {
      let connector = this.getConnector(connectorId);
      if (!connector) return;
      let mappedData = this.srv.defaultMappedData(this.dataSourceList);
      if (!mappedData) return;
      this.destinationConnectorList = this.destinationConnectorList || [];
      this.destinationConnectorList.push({
        id: connectorId,
        mappedData: mappedData
      });
      this.showConnectorSelector = false;
      this.setActiveCell();
      if (this.getConnectorDataList(connector.id).length) {
        this.buildSuggestionList(connector.id);
      } else {
        this.fetchDataList(connector.id);
        this.checking.push(connectorId);
      }
    },
    isEditing(sourceDataId, connectorId) {
      if (!this.editing) return false;
      return (
        this.editing.sourceDataId == sourceDataId &&
        this.editing.connectorId == connectorId
      );
    },
    setActiveCell(sourceDataId, connectorId) {
      if (sourceDataId && connectorId) {
        this.$set(this, "editing", {
          sourceDataId: sourceDataId,
          connectorId: connectorId
        });
      } else {
        this.$set(this, "editing", null);
        this.selectingData = false;
      }
    },
    setTargetData(srcDataId, dstDataId) {
      // console.log(srcDataId, dstDataId);
      if (!this.isEditing) return;
      let connMap = (this.destinationConnectorList || []).find(
        ({ id }) => id == this.editing.connectorId
      );
      if (connMap) {
        this.$set(connMap.mappedData, srcDataId, dstDataId || null);
      }
      this.setActiveCell();
    },
    delTargetData(srcDataId, connectorId) {
      let connMap = (this.destinationConnectorList || []).find(
        ({ id }) => id == connectorId
      );
      if (connMap) {
        connMap.mappedData[srcDataId] = null;
      }
      this.setActiveCell();
    },
    getConnectorName(connectorId) {
      return (
        (this.connectorList || []).find(({ id }) => id == connectorId)?.name ||
        ""
      );
    },
    getDataName(dataId) {
      return (this.dataList || []).find(({ id }) => id == dataId)?.name || "";
    },
    parseResponse(response) {
      if (response && response.mapping && response.etag && this.srv) {
        this.original = response;
        this.$set(
          this,
          "destinationConnectorList",
          this.srv.entry2destinationConnectorList(response.mapping)
        );
      }
    },
    resetConnector(connectorId) {
      let connMap = (this.destinationConnectorList || []).find(
        ({ id }) => id == connectorId
      );
      let mappedData = (connMap && connMap?.mappedData) || {};
      Object.keys(mappedData || {}).forEach((srcDataId) => {
        mappedData[srcDataId] = null;
      });
    },
    hasMappedData(connectorId) {
      let connMap = (this.destinationConnectorList || []).find(
        ({ id }) => id == connectorId
      );
      let mappedData = (connMap && connMap?.mappedData) || {};
      for (var srcDataId in mappedData) {
        if (mappedData[srcDataId]) return true;
      }
      return false;
    },
    toggleDataMapping(connectorId) {
      if (this.hasMappedData(connectorId)) {
        this.resetConnector(connectorId);
      } else {
        this.buildSuggestionList(connectorId);
      }
    },
    reset() {
      this.editing = null;
      this.showConnectorSelector = false;
      this.destinationConnectorList = null;
      this.busy = false;
      this.original = undefined;
    },
    restore() {
      this.parseResponse(this.original);
    },
    remove() {
      if (!this.original) return;
      const _remove = () => {
        let payload = {
          id: this.connectorId,
          etag: this.original.etag
        };
        this.busy = true;
        this.srv.remove(payload).then((ret) => {
          this.busy = false;
          if (this.validateDeleteResponse(ret)) {
            this.reset();
            this.read(); // it is performing the read operation cause it seems the mapping still require an etag // review it
            this.showAlert();
          } else {
            this.showAlert();
          }
        });
      };
      this.$swal({
        title: this.$t("are_you_sure"),
        content: this.removalMessage,
        icon: "warning",
        buttons: [this.$t("cancel"), this.$t("yes_delete_it")]
      }).then(function(isConfirm) {
        if (isConfirm) {
          _remove();
        }
      });
    },
    read() {
      // this.srv.test();
      const _parseResponse = (response) => {
        if (!response) return;
        this.parseResponse(response);
        let connectors = { found: 0, missing: 0 };
        (this.destinationConnectorList || []).forEach(({ id }) => {
          connectors[this.getConnector(id) ? "found" : "missing"] += 1;
          if (!this.getConnectorDataList(id).length) {
            this.fetchDataList(id);
          }
        });
        if (connectors.missing) {
          this.fetchDeviceList();
        }
      };
      this.srv.get(this.connectorId).then((response) => {
        _parseResponse(response);
      });
    },
    save() {
      if (!this.isValid) return;
      this.busy = true;
      this.srv.save(this.payload).then((response) => {
        this.busy = false;
        if (this.validateSaveResponse(response)) {
          this.parseResponse(response);
        } else {
          this.showAlert();
        }
      });
    },
    fetchDeviceList() {
      this.$store.dispatch("dashboard/fetchDevices").then((result) => {
        console.log("connectorList");
      });
    },
    fetchDataList(connectorId) {
      var query = {
        resource: "data",
        connectorId: connectorId,
        forceUpdate: false,
        once: true
      };
      this.busy = true;
      this.$store.dispatch("dashboard/fetchResourcesFrom", query).then(() => {
        this.busy = false;
      });
    },
    isMQTTConnector(connector) {
      // return isMQTT(this.equipment); // it seems to be wrongly cached by vue, while under anonymous mode
      return (connector && connector?.protocol?.is_mqtt_protocol) || false;
    },
    buildSuggestionList(connectorId) {
      // console.log(`buildSuggestionList ${connectorId}`);
      let all = this.getConnectorDataList(connectorId) || [];
      if (!all.length) return;
      let connMap = (this.destinationConnectorList || []).find(
        ({ id }) => id == connectorId
      );
      let mappedData = (connMap && connMap?.mappedData) || {};
      let checked = {};
      Object.keys(mappedData || {}).forEach((srcDataId) => {
        let srcData = this.getDataById(srcDataId);
        let dstDataId = mappedData[srcDataId];
        if (dstDataId || !srcData) return;
        // console.log(`mapping ${srcDataId} to ${connectorId}=>null`);
        let dstData = all.find((item) => {
          if (item.id in checked) return false;
          if (this.$utils.kebab(item.name) == this.$utils.kebab(srcData.name)) {
            return true;
          }
          // validates if it was already used
          return false;
        });
        if (dstData) {
          mappedData[srcDataId] = dstData.id;
          checked[dstData.id] = true;
        }
      });
    },
    isMultiDevice(connectorId) {
      let deviceIdList = new Set(
        (this.getConnectorDataList(connectorId) || []).map(
          ({ device }) => (device && device.id) || null
        )
      );
      return deviceIdList.size > 1;
    }
  },
  beforeCreate() {
    this.srv = new DataMirroring();
  },
  created() {
    this.fetchDataList(this.connectorId);
    this.read();
  },
  beforeDestroy() {
    delete this.srv;
  }
};
</script>

<style scoped>
.table-container {
  clear: both;
  width: 100%;
}

.table-content {
  overflow-x: auto;
  height: auto;
}

.table {
  width: 100%;
}

.table > thead > tr > th {
  vertical-align: middle;
  white-space: nowrap;
}

.table > thead > tr > th,
.table > tbody > tr > td {
  vertical-align: middle;
  padding: 8px 10px 8px 8px;
  max-width: 20%;
  min-width: 200px;
  min-height: 50px;
  height: auto;
  line-height: 1.9;
}

.table > thead > tr > th:nth-child(1),
.table > tbody > tr > td:nth-child(1) {
  width: 200px;
}

.table > thead > tr > th.no-data,
.table > tbody > tr > td.no-data {
  max-width: none;
  width: 80%;
}

td > .vselector {
  max-width: 300px;
  width: 100%;
  background-color: white;
}

td > span.btn,
th > span.btn {
  color: transparent;
  margin-left: 5px;
}

td:hover > span.btn,
th:hover > span.btn {
  background-color: #dfdfdf;
}

td:hover > span.btn.btn-trash,
th:hover > span.btn.btn-trash {
  color: rgb(141, 3, 3);
}

td:hover > span.btn-pencil,
th:hover > span.btn.pencil {
  color: #666;
}

th > span > i.fa,
td > span > i.fa {
  padding-right: 4px;
}

.clicable {
  opacity: 0.8;
  cursor: pointer;
}

.toolbar > span.btn {
  margin-left: 5px;
}

.form-footer {
  margin: 0px 15px;
  padding: 5px 0 50px 0;
  border-top: 1px solid lightgray;
  padding-bottom: 150px;
}

.form-footer > div.row {
  min-height: 35px;
}

.group-name {
  margin-left: -10px;
  font-size: 12pt;
  font-style: italic;
  font-weight: 600;
}
</style>
